<template>
    <div>

    </div>
</template>

<script>
    export default {
        name: "Index",
        components: {}
    }
</script>

<style scoped>
    .iframe {
        width: 100%;
        height: 100%;
    }
</style>